class App {
	constructor() {
		window.addEventListener('load', () => {
			this.init();
		}, false);
	}

	init() {
		this.substituteLinks();
	}

	substituteLinks() {
		const substituteWithCityId = (cityId) => {
			const $links = document.querySelectorAll('a[data-substitute="true"]');
			const cityInfo = craft.env.cityInfo.find((info) => info.id === cityId);

			if (cityInfo) {
				const cityUrl = cityInfo.url;

				if (cityUrl) {
					$links.forEach(($link) => {
						const href = $link.href.replace(/^http(s?):\/\/[^\/]+(?=\/)/, cityUrl);
						$link.href = href;
					});
				}
			}
		};

		let cityId = craft.env.cityId || costorage.get('h_city') || costorage.get('h_geo');

		if (!cityId) {
			window.geoCookieFetchCallbacks.push(() => {
				cityId = costorage.get('h_city');
				substituteWithCityId(cityId);
			});
		} else {
			substituteWithCityId(cityId);
		}
	}
}

const app = new App();